<div class="wrapper" [class.extra-space]="extraSpace==true" [ngStyle]="{ 'grid-template-columns': IsDashboard ? '1fr' : '1fr 400px'}">
	<div class="map" id="basemap" [ngStyle]="{ 'grid-area': IsDashboard ? 'auto' : 'map', 'height': IsDashboard ? '414px' : 'auto', 'width':'100%', 'border-radius':'10px'}"></div>
	<!-- TOGGLE RIGHTPANEL SHOW/HIDE -->
	<!-- <div *ngIf="extraSpace === false" id="closeInformation" (click)="CloseInformation()">
		<img src="assets/icons/svg/arrow-right.svg" class="smal-svg">
	</div>
	<div *ngIf="extraSpace === true" id="closeInformation" (click)="OpenInformation()">
		<img src="assets/icons/svg/arrow-left.svg" class="smal-svg show-arrow">
	</div> -->

	<div class="information" id="info" *ngIf="IsDashboard==false">

		<div class="btn-group btn-group-lg" class="contentSelector" id="contentSelector">
			<button type="button" class="btn content-btn" [class.active]="infoFocus=='trakkers'" title="{{'Page.Labels.UNITS' | translate}}" (click)="infoFocus='trakkers'; selectedTrakker=null; ScrollToTop();" id="infoFocusTrakkers">
				<img src="assets/icons/svg/trakker.svg" class="svg">
				<p class="text-label" translate>Page.Labels.UNITS</p>
			</button>
			<button type="button" class="btn content-btn" [class.active]="infoFocus=='geofences'" title="{{'Page.Labels.GEOFENCES' | translate}}" (click)="infoFocus='geofences'; selectedGeofence=null; ScrollToTop();" id="infoFocusGeofences">
				<img src="assets/icons/svg/geofence.svg" class="svg">
				<p class="text-label" translate>Page.Labels.GEOFENCES</p>
			</button>
			<button type="button" class="btn content-btn" [class.active]="infoFocus=='mapzones'" title="{{'Page.Labels.MAPZONES' | translate}}" (click)="infoFocus='mapzones'; selectedMapzone=null; ScrollToTop();" id="infoFocusMapzones">
				<img src="assets/icons/svg/mapzone.svg" class="svg">
				<p class="text-label" translate>Page.Labels.MAPZONES</p>
			</button>
			<!-- <button type="button" class="btn content-btn" [class.deactivated]="autoRefresh == false" ngbTooltip="{{'Page.Labels.AUTO_REFRESH' | translate}}"
			 (click)="AutoRefreshToggle()" id="infoAutoRefreshToggle">
				<img src="assets/icons/svg/refresh.svg" class="svg">
				<div class="tiny-meter"><span></span></div>
			</button> -->
			<!-- <button type="button" class="btn" (click)="infoFocus='geofences'">
				<i class="fas fa-exclamation-triangle"></i>
			</button> -->
		</div>

		<!-- COMPANY NAME AND NAME OF LOGGED IN USER -->
		<!-- <div class="infoHead" id="infoHead">
			<span *ngIf="customerName != null" class="company-name">{{ customerName }}</span>
			<span *ngIf="currentUser != null" class="account-name">{{ currentUser.Name}}</span>
		</div> -->
		<div class="infoContent" id="infoContent" (scroll)="OnScroll($event)">
			<!-- <div *ngIf="infoFocus == 'menu'">
				<div class="sectionhead">
					<h2 [translate]>Page.Labels.MENU</h2>
				</div>
				<button class="btn btn-sm" [class.active]="infoFocus=='trakkers'" (click)="infoFocus='trakkers';">Show trakkers</button>
				<button class="btn btn-sm" [class.active]="infoFocus=='geofences'" (click)="infoFocus='geofences';">Show geofences</button>
				<button class="btn btn-sm" [class.active]="infoFocus=='mapzones'" (click)="infoFocus='mapzones';">Show mapzones</button>
			</div> -->

			<div *ngIf="(!selectedTrakker || showSelectedInline) && infoFocus == 'trakkers'">
				<!-- <div (click)="infoFocus = 'menu'">Close</div> -->
				<!-- <div class="sectionhead"> -->
				<!-- <img src="assets/icons/svg/trakker.svg" class="svg"> -->
				<!-- <h2 [translate]>Page.Labels.UNITS</h2> -->
				<!-- </div> -->
				<div class="sectionTools position-sticky" style="top:0; z-index:100;">
					<a class="favoritesFilter" style="display: none;" (click)="ToggleFavoritesFilter()" ngbTooltip="{{ 'Page.ToolTips.FILTER_FAVORITES' | translate }}">
						<img *ngIf="!favoritesFilter" src="assets/icons/svg/favorite-false.svg" class="svg">
						<img *ngIf="favoritesFilter" src="assets/icons/svg/favorite-true.svg" class="svg">
					</a>
					<!-- <a class="searchButton" [class.active]="searchTrakker" (click)="ToggleTrakkerSearch()" title="{{'Page.ToolTips.SEARCH_TRAKKER' | translate}}" >
						<img src="assets/icons/svg/magnifying-glass.svg" class="svg">
					</a> -->

					<!-- <i class="far fa-fw fa-eye" [class.active]="listVisibleTrakkers" title="List visible on map"></i> -->
					<a class="zoomAll" style="display: none;" (click)="CenterOnMap(selectedGroup)" title="{{'Page.ToolTips.ZOOM_ALL_UNITS' | translate}}">
						<img src="assets/icons/svg/resize-to-fit.svg" class="svg">
					</a>

					<!-- <button class="btn btn-sm" (click)="showSelectedInline=true">Show selected inline</button>
					<button class="btn btn-sm" (click)="showSelectedInline=false">Show selected on separate page</button> -->

					<!-- <div *ngIf="searchTrakker"> -->
					<div>
						<input class="form-control input-sm" id="searchTrakkerText" placeholder="{{'Page.Labels.SEARCH_UNIT' | translate}}" type="search" (keyup.enter)="TryShowTrakkerOnMap()" (keyup.shift.enter)="TryToggleSelectTrakker()" (keyup.escape)="ToggleTrakkerSearch()" (input)="UpdateVisibleUnits($event)">
					</div>
				</div>

				<ng-container>
					<!-- <ng-container *ngIf="searchTrakker == false"> -->
					<!-- <span [translate]>Page.ToolTips.ZOOM_ALL_UNITS_IN_GROUP</span> -->

					<!-- GROUP DROPDOWN -->
					<div class="boxlabel-small">
						<p [translate]>Page.Labels.FILTER_WITHIN_GROUP</p>
					</div>
					<div class="dropdown">
						<div class="objectWrapper selected">
							<div class="object" (click)="GroupDropdown(selectedGroup)">
								<div class="icon" (click)="CenterOnMap(selectedGroup);$event.stopPropagation()" title="{{'Page.ToolTips.ZOOM_ALL_UNITS_IN_GROUP' | translate}}">
									<img src="assets/icons/svg/group.svg" class="svg">
								</div>
								<div *ngIf="selectedGroup == null" [translate]>Page.Labels.ALL_UNITS</div>
								<div *ngIf="selectedGroup != null">{{selectedGroup.Name}}</div>
								<div class="status">
									<span class="right" *ngIf="groupDropdownVisible == false">
										<img src="assets/icons/svg/caret-down.svg" class="smal-svg">
									</span>
									<span class="right" *ngIf="groupDropdownVisible == true">
										<img src="assets/icons/svg/caret-up.svg" class="smal-svg">
									</span>
								</div>
							</div>
						</div>
						<div class="dropdown-content" *ngIf="groupDropdownVisible == true">
							<input class="form-control input-sm" id="searchGroupText" placeholder="{{'Page.Placeholders.SEARCH_GROUP_NAME' | translate}}" type="search" (keyup.enter)="TrySelectGroup()" (keyup.escape)="ToggleGroupSearch()" (input)="UpdateVisibleGroups($event)">
							<div class="sectionTools">
								<a class="groupFavoritesFilter" (click)="ToggleGroupFavoritesFilter()" title="{{'Page.ToolTips.FILTER_FAVORITES' | translate}}">
									<img *ngIf="!groupFavoritesFilter" src="assets/icons/svg/favorite-false.svg" class="svg">
									<img *ngIf="groupFavoritesFilter" src="assets/icons/svg/favorite-true.svg" class="svg">
								</a>
								<a title="{{'Page.ToolTips.SORT_BY_NAME' | translate}}" (click)="SortGroupsBy('name');" [class.inactive]="sortGroupsBy!='name'">
									<img src="assets/icons/svg/sort-alphabetical.svg" class="svg">
								</a>
								<a title="{{'Page.ToolTips.SORT_BY_NUMBER_OF_UNITS' | translate}}" (click)="SortGroupsBy('trakkers');" [class.inactive]="sortGroupsBy!='trakkers'">
									<img src="assets/icons/svg/sort-by-amount.svg" class="svg">
								</a>
								<a title="{{'Page.ToolTips.HIDE_EMPTY_GROUPS' | translate}}" (click)="ToggleHideEmptyGroups()" [class.inactive]="!hideEmptyGroups">
									<img src="assets/icons/svg/hide-empty.svg" class="svg">
								</a>
								<a title="{{'Page.ToolTips.EDIT_GROUPS' | translate}}" [routerLink]="['/account/profile']" routerLinkActive="router-link-active" *ngIf="currentUser.Role!='User'">
									<img src="assets/icons/svg/edit.svg" class="svg">
								</a>
								<!-- <a class="right" (click)="ToggleGroupSearch()" title="Close group menu"><i class="fas fa-fw fa-times"></i></a> -->
							</div>

							<div class="group-scroller">
								<div class="objectWrapper" [class.selected]="selectedGroup == null">
									<!-- <div class="objectWrapper" *ngIf="groupSearchResult.length == groupList.length" [class.selected]="selectedGroup == null" > -->
									<div class="object" (click)="GroupDropdown(null)">
										<div class="icon" (click)="CenterOnMap(null);$event.stopPropagation()"><img src="assets/icons/svg/group.svg" class="svg"></div>
										<div><span [translate]>Page.Labels.ALL_UNITS</span> ({{unitList.length}})</div>
										<div class="status">
											<!-- <span class="right bullet"><i class="fas fa-fw fa-map-marker"></i>: {{unitList.length}} </span> -->
										</div>
									</div>
								</div>
								<ng-container *ngIf="groupSearchResult.length == 0">{{'Page.Labels.NO_GROUPS_FOUND' | translate}}</ng-container>
								<ng-container *ngFor="let group of groupSearchResult | sort:'Name'; trackBy:GroupIdentify">
									<div class="objectWrapper" [class.selected]="selectedGroup == group">
										<div class="object" (click)="GroupDropdown(group);">
											<div class="icon" (click)="CenterOnMap(group);$event.stopPropagation()" title="{{'Page.ToolTips.ZOOM_IN_GROUP' | translate}}">
												<img src="assets/icons/svg/group.svg" class="svg">
											</div>
											<div>{{group.Name}} ({{group.Trakkers.length}})</div>
											<div class="status" style="text-align: right;">
												<!-- <span class="right bullet"><i class="fas fa-fw fa-map-marker"></i>: {{group.Trakkers.length}}</span> -->
												<a *ngIf="currentUser.Role != 'AccountOwner'" title="{{ 'Page.ToolTips.FAVORITE' | translate }}" (click)="TryToggleFavoriteGroup(group);;$event.stopPropagation()">
													<img *ngIf="!group.IsFavorite" src="assets/icons/svg/favorite-false.svg" class="svg">
													<img *ngIf="group.IsFavorite" src="assets/icons/svg/favorite-true.svg" class="svg">
												</a>
											</div>
										</div>
									</div>
								</ng-container>
							</div>


						</div>
					</div>


					<!-- GEOFENCE DROPDOWN (JOHN) -->
					<div class="boxlabel-small">
						<p [translate]>Page.Labels.FILTER_WITHIN_GEOFENCE</p>
					</div>
					<div class="dropdown">
						<div class="objectWrapper selected">
							<div class="object" (click)="GeoDropdown(selectedGeo)">
								<div class="icon" (click)="CenterOnMap(selectedGeo);$event.stopPropagation()" title="{{'Page.ToolTips.ZOOM_IN_GEOFENCE' | translate}}">
									<img src="assets/icons/svg/geofence.svg" class="svg">
								</div>
								<div *ngIf="selectedGeo == null" [translate]>Page.Labels.NO_GEOFENCE_SELECTED</div>
								<div *ngIf="selectedGeo != null">{{selectedGeo.Name}}</div>
								<div class="status">
									<span class="right" *ngIf="geoDropdownVisible == false">
										<img src="assets/icons/svg/caret-down.svg" class="smal-svg">
									</span>
									<span class="right" *ngIf="geoDropdownVisible == true">
										<img src="assets/icons/svg/caret-up.svg" class="smal-svg">
									</span>
								</div>
							</div>
						</div>
						<div class="dropdown-content" *ngIf="geoDropdownVisible == true">
							<input class="form-control input-sm" id="searchGeoText" placeholder="{{'Page.Placeholders.SEARCH_GEOFENCE_NAME' | translate}}" type="search" (keyup.enter)="TrySelectGeo()" (keyup.escape)="ToggleGeoSearch()" (input)="UpdateVisibleGeos($event)">
							<div class="sectionTools">
								<a title="{{'Page.ToolTips.SORT_BY_NAME' | translate}}" (click)="SortGeosBy('name');" [class.inactive]="sortGeosBy!='name'">
									<img src="assets/icons/svg/sort-alphabetical.svg" class="svg">
								</a>
								<a title="{{'Page.ToolTips.SORT_BY_NUMBER_OF_UNITS' | translate}}" (click)="SortGeosBy('trakkers');" [class.inactive]="sortGeosBy!='trakkers'">
									<img src="assets/icons/svg/sort-by-amount.svg" class="svg">
								</a>
								<!-- <a title="{{'Page.ToolTips.HIDE_EMPTY_GROUPS' | translate}}" (click)="ToggleHideEmptyGroups()" [class.inactive]="!hideEmptyGroups">
									<img src="assets/icons/svg/hide-empty.svg" class="svg">
								</a>
								<a title="{{'Page.ToolTips.EDIT_GROUPS' | translate}}" [routerLink]="['/account/profile']" routerLinkActive="router-link-active" *ngIf="currentUser.Role!='User'">
									<img src="assets/icons/svg/edit.svg" class="svg">
								</a> -->
								<!-- <a class="right" (click)="ToggleGroupSearch()" title="Close group menu"><i class="fas fa-fw fa-times"></i></a> -->
							</div>

							<div class="geo-scroller">
								<div class="objectWrapper" [class.selected]="selectedGeo == null">
									<!-- <div class="objectWrapper" *ngIf="groupSearchResult.length == groupList.length" [class.selected]="selectedGeofence == null" > -->
									<div class="object" (click)="GeoDropdown(null)">
										<div class="icon" (click)="CenterOnMap(null);$event.stopPropagation()"><img src="assets/icons/svg/geofence.svg" class="svg"></div>
										<div><span [translate]>Page.Labels.NO_GEOFENCE_SELECTED</span> ({{unitList.length}})</div>
										<div class="status">
											<!-- <span class="right bullet"><i class="fas fa-fw fa-map-marker"></i>: {{unitList.length}} </span> -->
										</div>
									</div>
								</div>
								<ng-container *ngIf="geoSearchResult.length == 0">{{'Page.Labels.NO_GEOFENCES_FOUND' | translate}}</ng-container>
								<ng-container *ngFor="let geo of geoSearchResult | sort:'this.SortGeosBy'; trackBy:GeoIdentify">
									<div *ngIf="geo.GeofenceType !== 'Parking'" class="objectWrapper" [class.selected]="selectedGeo == geo">
										<div class="object" (click)="GeoDropdown(geo);">
											<div class="icon" (click)="CenterOnMap(geo);$event.stopPropagation()" title="{{'Page.ToolTips.ZOOM_ALL_UNITS_IN_GEOFENCE' | translate}}">
												<img *ngIf="geo.GeofenceType == 'Enter'" src="assets/icons/svg/trakker-events/enter-geofence.svg" class="svg">
												<img *ngIf="geo.GeofenceType == 'Exit'" src="assets/icons/svg/trakker-events/exit-geofence.svg" class="svg">
												<img *ngIf="geo.GeofenceType == 'Intersect'" src="assets/icons/svg/trakker-events/intersect-geofence.svg" class="svg">
												<p *ngIf="geo.GeofenceType == 'Parking'">PARK</p>
											</div>
											<div>{{geo.Name}}</div>
											<!-- <div>{{geo.Name}} ({{unitList.length}})</div> -->
										</div>
									</div>
								</ng-container>
							</div>
							<!-- <img src="/assets/image.png" alt=""> -->

						</div>
					</div>
				</ng-container>
				<!-- <span style="font-size: 12px" [translate]>Page.ToolTips.CLICK_TO_ZOOM_IN_UNIT</span> -->
				
				<div *ngIf="listTrakkersBy=='name'" style="margin-top: 4px;" id="unitList" [ngClass]="{'lowOpacity': groupDropdownVisible || geoDropdownVisible}">

					<span *ngIf="trakkerSearchResult.length == 0 && modal != 'loading' && !selectedGroup && !selectedGeo" [translate]>Page.Labels.NO_UNITS_FOUND</span>
					<span *ngIf="trakkerSearchResult.length == 0 && modal != 'loading' && selectedGroup && !selectedGeo" [translate]>Page.Labels.NO_UNITS_FOUND_IN_GROUP</span>
					<span *ngIf="trakkerSearchResult.length == 0 && modal != 'loading' && !selectedGroup && selectedGeo" [translate]>Page.Labels.NO_UNITS_FOUND_WITHIN_GEOFENCE</span>
					<span *ngIf="trakkerSearchResult.length == 0 && modal != 'loading' && selectedGroup && selectedGeo" [translate]>Page.Labels.NO_UNITS_FOUND_IN_GROUP_WITHIN_GEOFENCE</span>

					<span *ngIf="modal == 'loading'" [translate]>Page.Labels.LOADING</span>
					
					<ng-container *ngIf="modal != 'loading'">

						<!-- TRAKKER -->
						<!-- [@slideInOut]  -->
						<ng-container *ngFor="let trakker of trakkerSearchResult | sort:'Name'; trackBy:TrakkerIdentify; let i = index">
							<div *ngIf="i < 25 * currentPage" [ngClass]="getBackgroundClass(trakker)" [class.ev-sos]="GetTrakkerActiveAlerts(trakker.TrakkerId) | isSOS" [class.selected]="selectedTrakker==trakker">

								<div class="object" id="trakker{{trakker.TrakkerId}}" (click)="ToggleSelectedTrakker(trakker);" style="display: flex; flex-direction: row; justify-content: space-between;">
									<div style="display: flex; flex-direction: row; align-items: center;">
										<div class="icon" (click)="CenterOnMap(trakker);$event.stopPropagation()" title="{{'Page.ToolTips.CLICK_TO_ZOOM_IN_UNIT' | translate}}">
											<img src="/assets/{{ trakker.Icon.Url }}" alt="icon">
										</div>
										<div>
											<ng-container *ngIf="trakker.Name != null">
												{{ trakker.Name }}
												<span *ngIf="trakker?.Hardware?.EmergencyModeEnabled" [translate] style="color: #B32;">Page.Labels.EMERGENCY_TRACKING_ACTIVATED_1</span>
												<!-- ({{ trakker | isSOS }}) -->
											</ng-container>
											<ng-container *ngIf="trakker.Vehicles.length > 0 && trakker.Vehicles[0].VehicleRegistrationNumber && trakker.Name == false">
												<span class="reg-number-text">{{ trakker.Vehicles[0].VehicleRegistrationNumber }}</span>
											</ng-container>
	
	
										</div>
									</div>
									<div class="status trakker-status">
										<colorIcon *ngIf="CountTriggeredGeofence(trakker,'exit') > 0" type="exit-geofence" [title]="CountTriggeredGeofence(trakker,'exit') + ' ' + ('Page.Labels.EXITGEOFENCE' | translate | lowercase )">
										</colorIcon>
										<colorIcon *ngIf="CountTriggeredGeofence(trakker,'enter') > 0" type="enter-geofence" [title]="CountTriggeredGeofence(trakker,'enter') + ' ' + ('Page.Labels.ENTERGEOFENCE' | translate | lowercase )">
										</colorIcon>
										<colorIcon [type]="trakker.Status"></colorIcon>
										<div class="avatar">
											<batteryLevel [trakker]="trakker"></batteryLevel>
										</div>
	
									</div>
								</div>
								<div class="objectDetails" *ngIf="selectedTrakker == trakker">
									<ng-container *ngIf="!MapDisabled">
										<div class="sectionTools">
											<a ngbTooltip="{{ 'Page.ToolTips.FAVORITE' | translate }}" (click)="TryToggleFavorite(trakker);">
												<img *ngIf="!trakker.IsFavorite" src="assets/icons/svg/favorite-false.svg" class="svg">
												<img *ngIf="trakker.IsFavorite" src="assets/icons/svg/favorite-true.svg" class="svg">
											</a>
											<a ngbTooltip="{{ 'Page.ToolTips.CLICK_TO_ZOOM_IN_UNIT' | translate }}" tooltipClass="tooltip-z" (click)="CenterOnMap(trakker);">
												<img src="assets/icons/svg/crosshair.svg" class="svg">
											</a>
											<a ngbTooltip="{{ 'Page.ToolTips.FOLLOW_ON_MAP' | translate }}" tooltipClass="tooltip-z" (click)="ToggleFollowObject(trakker)" [class]="followingObject == trakker ? 'active': 'inactive'">
												<img src="assets/icons/svg/path.svg" class="svg">
											</a>
											<a ngbTooltip="{{ 'Page.ToolTips.POSITIONS_LATEST_HOUR' | translate }}" (click)="TryShowLastHour(trakker);$event.stopPropagation()">
												<img src="assets/icons/svg/last-hour.svg" class="svg">
											</a>
											<a ngbTooltip="{{ 'Page.ToolTips.10_LATEST_POSITIONS' | translate }}" (click)="TryShowLastTen(trakker);$event.stopPropagation()">
												<img src="assets/icons/svg/last-10.svg" class="svg">
											</a>
											<a ngbTooltip="{{ 'Page.Labels.CHANGE_ICON' | translate }}" class="changeIconButton" (click)="StartChangeIcon()">
												<img src="assets/icons/svg/images.svg" class="svg">
											</a>
											<a *ngIf="currentUser.Role != 'User'" ngbTooltip="{{ 'Page.Labels.EDIT_NAME' | translate }}" class="editButton" (click)="StartEditTrakkerName()" [class.inactive]="!editTrakkerName">
												<img src="assets/icons/svg/edit.svg" class="svg">
											</a>
	
										</div>
										<ng-container *ngIf="editTrakkerName">
											<a [routerLink]="['/units/' + trakker.TrakkerId]" routerLinkActive="router-link-active" class="twoCols">
												<span class="pl-1">{{ 'Page.Messages.EDIT_ON_INFORMATION_PAGE' | translate }}</span>
												<div style="text-align: center;align-self: center;">
													<img src="assets/icons/svg/arrow-right.svg" class="svg">
												</div>
											</a>
										</ng-container>
									</ng-container>
	
									<ng-container *ngIf="trakker.Vehicles.length > 0 ">
										<div class="boxlabel">{{ 'Page.Labels.INFORMATION' | translate }}</div>
										<div class="event">
											<div class="info-row">
												<p class="label">{{ 'Page.Labels.NAME' | translate }}</p>
												<p class="data">{{ trakker.Name }}</p>
											</div>
											<div class="info-row">
												<p class="label">{{ 'Page.Labels.EQUIPMENT_NUMBER' | translate }}</p>
												<p class="data">{{ trakker.EquipmentNumber }}</p>
											</div>
											<div class="info-row">
												<p class="label">{{ 'Page.Labels.REGISTRATION_NUMBER' | translate }}</p>
												<p class="data" *ngIf="trakker.Vehicles[0].VehicleRegistrationNumber != null">
													{{ trakker.Vehicles[0].VehicleRegistrationNumber }}
												</p>
											</div>
											<div class="info-row">
												<p class="label">{{ 'Page.Labels.DRIVER' | translate }}</p>
												<p class="data">{{ trakker.Vehicles[0].Driver }}</p>
											</div>
											<div class="info-row">
												<p class="label">{{ 'Page.Labels.WORKING_STATUS' | translate }}</p>
												<p class="data working-status">
													<span *ngIf="trakker.Vehicles[0].WorkingStatus == 0">{{ 'Page.Labels.NA' | translate }}</span>
													<span *ngIf="trakker.Vehicles[0].WorkingStatus == 'PrivateUse'">{{ 'Page.Labels.PRIVATE' | translate }}</span>
													<span *ngIf="trakker.Vehicles[0].WorkingStatus == 'WorkRelatedUse'">{{ 'Page.Labels.WORKING' | translate }}</span>
												</p>
											</div>
											<div class="info-row">
												<p class="label">{{ 'Page.Labels.ODOMETER' | translate }}</p>
												<p class="data">
													<span class="odometer-text" *ngIf="trakker.Vehicles[0].OdometerKilometer != null"><span>{{ trakker.Vehicles[0].OdometerKilometer }}</span></span>
													<span class="odometer-text" *ngIf="trakker.Vehicles[0].OdometerKilometer == null"><span>{{ 'Page.Labels.NA' | translate }}</span></span>
												</p>
											</div>
											<a [routerLink]="['/units/'+trakker.Vehicles[0].TrakkerId]" routerLinkActive="router-link-active" class="twoCols">
												<span>{{ 'Page.Labels.MORE_ON_DETAILS_PAGE' | translate }} </span>
												<div style="text-align: center;align-self: center;">
													<img src="assets/icons/svg/arrow-right.svg" class="svg">
												</div>
											</a>
										</div>
	
									</ng-container>
									<ng-container *ngIf="trakker.Asset">
										<div class="boxlabel">{{ 'Page.Labels.INFORMATION' | translate }}</div>
										<div class="event">
											<div class="info-row">
												<p class="label">{{ 'Page.Labels.NAME' | translate }}</p>
												<p class="data">{{ trakker.Name }}</p>
											</div>
											<div class="info-row">
												<p class="label">{{ 'Page.Labels.EQUIPMENT_NUMBER' | translate }}</p>
												<p class="data">{{ trakker.EquipmentNumber }}</p>
											</div>
											<a [routerLink]="['/units/'+trakker.TrakkerId]" routerLinkActive="router-link-active" class="twoCols">
												<span>{{ 'Page.Labels.MORE_ON_DETAILS_PAGE' | translate }} </span>
												<div style="text-align: center;align-self: center;">
													<img src="assets/icons/svg/arrow-right.svg" class="svg">
												</div>
											</a>
										</div>
									</ng-container>
									<ng-container *ngIf="!MapDisabled">
										<div class="boxlabel">{{ 'Page.Labels.LATEST_POSITION' | translate }}</div>
										<div class="twoCols event">
											<div style="padding: 5px;">
												<address [event]="trakker.LatestPositionEvent" [handleError]="false"></address>
												<ng-container *ngIf="trakker.LatestPositionEvent != null">
													<p>{{ trakker.LatestPositionEvent.OccuredOn | ago:translationsAgo }}</p>
													<p>
														<dateTime [utcDateTime]="trakker.LatestPositionEvent.OccuredOn" [locales]="dateTimeLocales" [options]="dateTimeOptions"></dateTime>
													</p>
													<p *ngIf="trakker.LatestPositionEvent.PositionSourceType!='Undefined'">{{ 'Page.Labels.PositionSourceType' | translate }} : {{ trakker.LatestPositionEvent.PositionSourceType }}</p>
												</ng-container>
											</div>
	
										</div>
									</ng-container>
									<!-- LATEST EVENT : IF NOT ASSET (TRAKKER EVENT) -->
									<ng-container *ngIf="!trakker.Asset && !MapDisabled">
										<div class="boxlabel">{{ 'Page.Labels.LATEST_EVENT' | translate }}</div>
										<div class="twoCols event">
											<div style="padding: 5px;">
												<ng-container *ngIf="trakker.LatestEvent != null">
													<p>
														<eventType [event]="trakker.LatestEvent" outputType="text"></eventType>
													</p>
													<p>{{ trakker.LatestEvent.OccuredOn | ago:translationsAgo }}</p>
													<p>
														<dateTime [utcDateTime]="trakker.LatestEvent.OccuredOn" [locales]="dateTimeLocales" [options]="dateTimeOptions"></dateTime>
													</p>
												</ng-container>
												<p *ngIf="trakker.LatestEvent == null">{{ 'Page.Labels.NO_LATEST_EVENT' | translate }}</p>
											</div>
											<div style="text-align: center;align-self: center;">
												<div>
													<eventType [event]="trakker.LatestEvent" style="font-size: 40px !important;"></eventType>
													<p>
														<eventType [event]="trakker.LatestEvent" outputType="text"></eventType>
													</p>
												</div>
											</div>
										</div>
									</ng-container>
	
									<!-- LATEST EVENT : IF ASSET (OPERATION TIME COMPONENT) -->
									<app-operation-time *ngIf="trakker && (trakker.Asset != null)" [LatestEvent]="trakker.Asset.LatestEvent"></app-operation-time>
	
									<ng-container *ngIf="AnyActiveAlerts(trakker.TrakkerId)">
	
										<div class="boxlabel">{{ 'Page.Labels.ACTIVE_ALERTS' | translate }}</div>
										<ng-container *ngFor="let alert of GetTrakkerActiveAlerts(trakker.TrakkerId); index as i">
											<div *ngIf="i <= 3" class="threeCols event" [class.ev-sos]="alert.TrakkerEvent | isSOS">
												<div class="icon">
													<eventType [event]="alert.TrakkerEvent" style="font-size: 28px !important; padding:2px;"></eventType>
												</div>
												<div>
													<p>
														<eventType [event]="alert.TrakkerEvent" outputType="text"></eventType>
														<ng-container *ngIf="alert.TrakkerEvent.AlertType.includes('Geofence') && alert.TrakkerEvent.TriggeredGeofenceName">({{ alert.TrakkerEvent.TriggeredGeofenceName }})</ng-container>
													</p>
													<p>{{ alert.TrakkerEvent.OccuredOn | ago:translationsAgo }}</p>
													<p>
														<dateTime [utcDateTime]="alert.TrakkerEvent.OccuredOn" [locales]="dateTimeLocales" [options]="dateTimeOptions"></dateTime>
													</p>
												</div>
												<div *ngIf="alert.TrakkerEvent | isSOS" style="text-align: right; padding-right: 25px;">
													<a (click)="TryConfirmAlert(alert.TrakkerEvent,trakker)" title="{{ 'Page.ToolTips.CONFIRM' | translate }}">
														<img src="assets/icons/svg/checkbox-true.svg" style="width: 42px; height: 42px;">
													</a>
												</div>
											</div>
										</ng-container>
										<div *ngIf="trakker.ActiveAlerts?.length > 4" class="redundance-box">+ {{ trakker.ActiveAlerts.length - 4 }}</div>
									</ng-container>
									<!-- <h6>Settings: <a (click)="StartEditTrakkerSettings()" title="Edit settings"><i class="fas fa-cog"></i></a></h6>
									<p></p> -->
									<div class="boxlabel">{{ 'Page.Labels.CONNECTED_GEOFENCES' | translate }}</div>
									<div class="twoCols event">
										<div>
											<div *ngIf="trakker.Geofences.length == 0" style="padding-left: 5px;">{{ 'Page.Labels.NO_GEOFENCES_CONNECTED' | translate }}</div>
											<ng-container *ngFor="let geofence of trakker.Geofences | sort:'Name'; trackBy:GeofenceIdentify; index as i">
												<div *ngIf="i <= 2" class="connectedObject">
													<span>
														<img *ngIf="geofence.GeofenceType == 'Exit'" src="assets/icons/svg/trakker-events/exit-geofence.svg" class="svg">
														<img *ngIf="geofence.GeofenceType == 'Enter'" src="assets/icons/svg/trakker-events/enter-geofence.svg" class="svg">
														<img *ngIf="geofence.GeofenceType == 'Intersect'" src="assets/icons/svg/trakker-events/intersect-geofence.svg" class="svg">
														<img *ngIf="geofence.GeofenceType == 'Parking'" src="assets/icons/svg/parking-geofence.svg" class="svg">
														{{ geofence.Name }}
													</span>
													<button title="{{ 'Page.Buttons.REMOVE' | translate }}" (click)="RemoveGeofenceFromObject(trakker,geofence)">
														<img src="assets/icons/svg/remove.svg">
													</button>
												</div>
											</ng-container>
											<div *ngIf="trakker.Geofences.length > 3" class="redundance-box">+ {{ trakker.Geofences.length - 3 }}</div>
										</div>
										<div style="font-size: 40px !important;text-align: center; cursor: pointer;">
											<a (click)="StartAddGeofence(trakker)" title="{{ 'Page.ToolTips.CHANGE_CONNECTED_GEOFENCES' | translate }}">
												<img src="assets/icons/svg/edit.svg" style="width: 42px; height: 42px;">
											</a>
										</div>
									</div>
								</div>

							</div>
							
						</ng-container>
					</ng-container>
				</div>
			</div>

			<div *ngIf="infoFocus=='geofences'">
				<!-- <div (click)="infoFocus = 'menu'">Close</div> -->
				<!-- <div class="sectionhead">
					<img src="assets/icons/svg/geofence.svg" class="svg">
					<span class="header" [translate]>Page.Labels.GEOFENCES</span>
				</div> -->
				<div class="sectionTools">
					<!-- <a title="{{ 'Page.ToolTips.SEARCH_GEOFENCE' | translate }}" class="searchButton" [class.inactive]="!searchGeofence" (click)="ToggleGeofenceSearch();" *ngIf="geofenceList.length > 5" >
						<img src="assets/icons/svg/magnifying-glass.svg" style="width: 32px; height: 32px;">
					</a> -->
					<!-- <a title="List visible on map"><i class="far fa-fw fa-eye" [class.active]="search"></i></a> -->
					<div class="geofence-buttons">
						<button *ngIf="!showAllGeofences" class="btn-base btn-options" style="min-height: 36px;" ngbTooltip="{{ 'Page.ToolTips.DRAW_ALL_GEOFENCES' | translate }}" (click)="ShowAllGeofences()">
							<img class="svgee" src="assets/icons/svg/checkbox-false.svg">
							<span translate>Page.Labels.DRAW_ALL_GEOFENCES</span>
						</button>

						<button *ngIf="showAllGeofences" class="btn-base btn-save" style="min-height: 36px;" ngbTooltip="{{ 'Page.ToolTips.DRAW_ALL_GEOFENCES' | translate }}" (click)="ShowAllGeofences()">
							<img class="svgee" src="assets/icons/svg/checkbox-true.svg">
							<span translate>Page.Labels.DRAW_ALL_GEOFENCES</span>
						</button>

						<button class="btn-base btn-options" ngbTooltip="{{ 'Page.ToolTips.CREATE_GEOFENCE' | translate }}" (click)="StartCreateGeofence()">
							<img class="svgee" src="assets/icons/svg/add.svg">
							<span translate>Page.Labels.CREATE_GEOFENCE</span>
						</button>
					</div>

					<!-- <a title="{{ 'Page.ToolTips.DRAW_ALL_GEOFENCES' | translate }}" class="zoomAll" (click)="ShowAllGeofences()">
						<img src="assets/icons/svg/draw-all-geofences.svg" class="svg" [class.inactive]="!showAllGeofences">
					</a>
					<a title="{{ 'Page.ToolTips.CREATE_GEOFENCE' | translate }}" class="insertButton" (click)="StartCreateGeofence()" *ngIf="currentUser.Role!='User'" >
						<img src="assets/icons/svg/add.svg" class="svg">
					</a> -->

					<!-- <div *ngIf="searchGeofence && geofenceList.length > 5"> -->
					<div>
						<input class="form-control input-sm" id="searchGeofenceText" placeholder="{{ 'Page.ToolTips.SEARCH_GEOFENCE' | translate }}" type="search" (keyup.enter)="TryShowGeofenceOnMap()" (keyup.shift.enter)="TryToggleSelectGeofence()" (keyup.escape)="ToggleGeofenceSearch()" (input)="UpdateVisibleGeofences($event)">
						<!-- <button (click)="searchForGeofence()">search</button> -->
					</div>
				</div>
				<div *ngIf="geofenceList.length == 0">{{ 'Page.Labels.NO_GEOFENCES' | translate }}</div>
				<div *ngIf="geofenceList.length > 0">
					<!-- <span>{{ 'Page.ToolTips.CLICK_TO_DRAW_GEOFENCE' | translate }}</span> -->
					<div *ngFor="let geofence of geofenceSearchResult | sort:'Name' ; trackBy:GeofenceIdentify" class="objectWrapper" [class.selected]="selectedGeofence == geofence">
						<ng-container *ngIf="geofenceSearchResult.length == 0">{{ 'Page.Labels.NO_GEOFENCES_FOUND' | translate }}</ng-container>
						<div class="object" (click)="CenterOnMap(geofence);ToggleSelectedGeofence(geofence);" id="geofence{{geofence.GeofenceId}}">
							<div class="icon">
								<img *ngIf="geofence.GeofenceType == 'Exit'" src="assets/icons/svg/trakker-events/exit-geofence.svg" class="svg">
								<img *ngIf="geofence.GeofenceType == 'Enter'" src="assets/icons/svg/trakker-events/enter-geofence.svg" class="svg">
								<img *ngIf="geofence.GeofenceType == 'Intersect'" src="assets/icons/svg/trakker-events/intersect-geofence.svg" class="svg">
								<img *ngIf="geofence.GeofenceType == 'Parking'" src="assets/icons/svg/parking-geofence.svg" class="svg">
							</div>
							<div class="geofence-name">{{ geofence.Name }}</div>
							<div class="status geofence-status">

								<div class="geo-con-unit">
									<div *ngIf="geofence.Trakkers" [ngClass]="{'inactive-el': (geofence.Trakkers.length < 1 && !geofence.ActiveForAllUnits)}" ngbTooltip="{{ 'Page.Messages.TRAKKERS_INFO' | translate }}">
										<p class="sm-text" [translate]>Page.Labels.UNITS<span>: </span></p>
										<p *ngIf="!geofence.ActiveForAllUnits" class="sm-text">{{ geofence.Trakkers.length }}</p>
										<p *ngIf="geofence.ActiveForAllUnits" class="sm-text">{{ 'Page.Labels.ALL' | translate }}</p>
									</div>
								</div>

								<div class="icons">
									<colorIcon type="alert" [disabled]="false" *ngIf="calculateTrakkersTriggeringGeofence(geofence) > 0" [title]="calculateTrakkersTriggeringGeofence(geofence) == 0 ? ('Page.Messages.X_UNITS_HAS_TRIGGERED_THIS_GEOFENCE' | translate | stringFormat: { 'X': 0 }):
										(calculateTrakkersTriggeringGeofence(geofence) != 1 ?('Page.Messages.X_UNITS_HAS_TRIGGERED_THIS_GEOFENCE' | translate | stringFormat: { 'X': calculateTrakkersTriggeringGeofence(geofence) }):
										'Page.Messages.ONE_UNIT_HAS_TRIGGERED_THIS_GEOFENCE' | translate)" [class.disabled]="calculateTrakkersTriggeringGeofence(geofence) == 0 || calculateTrakkersTriggeringGeofence(geofence) == 0">
									</colorIcon>
									<colorIcon type="alert" [disabled]="true" *ngIf="calculateTrakkersTriggeringGeofence(geofence) == 0" [title]="calculateTrakkersTriggeringGeofence(geofence) == 0 ? ('Page.Messages.X_UNITS_HAS_TRIGGERED_THIS_GEOFENCE' | translate | stringFormat: { 'X': 0 }):
										(calculateTrakkersTriggeringGeofence(geofence) != 1 ?('Page.Messages.X_UNITS_HAS_TRIGGERED_THIS_GEOFENCE' | translate | stringFormat: { 'X': calculateTrakkersTriggeringGeofence(geofence) }):
										'Page.Messages.ONE_UNIT_HAS_TRIGGERED_THIS_GEOFENCE' | translate)" [class.disabled]="calculateTrakkersTriggeringGeofence(geofence) == 0 || calculateTrakkersTriggeringGeofence(geofence) == 0">
									</colorIcon>
									<colorIcon [type]="geofence.IsTimeControlled==true?'clock':'twentyfour-seven'">
									</colorIcon>
								</div>

							</div>
						</div>
						<div class="objectDetails" *ngIf="selectedGeofence == geofence">
							<div class="sectionTools" style="display: flex; flex-direction: row; justify-content: space-between;">
								<div>
									<a (click)="CenterOnMap(geofence);" title="{{ 'Page.ToolTips.CLICK_TO_DRAW_GEOFENCE' | translate }}">
										<img src="assets/icons/svg/geofence-on-map.svg" class="svg" [class.inactive]="!IsGeofenceVisible(geofence)">
									</a>
									<ng-container>
										<a class="editButton" (click)="StartEditGeofenceSettings()" title="{{ 'Page.ToolTips.EDIT_SETTINGS' | translate }}">
											<img src="assets/icons/svg/edit.svg" class="svg">
										</a>
									</ng-container>
								</div>

								<ng-container>
									<button class="btn-base btn-del" ngbTooltip="{{ 'Page.ToolTips.DELETE_GEOFENCE' | translate }}" (click)="VerifyDeleteGeofence(geofence)">
										<img class="svgee" src="assets/icons/svg/delete.svg">
										<span translate>Page.Buttons.DELETE</span>
									</button>
								</ng-container>

							</div>
							<div class="boxlabel">{{ 'Page.Labels.INFORMATION' | translate }}</div>
							<div class="event">
								<div *ngIf="geofence.IsTimeControlled==true">
									{{ 'Page.ToolTips.CONTROLLED_BY_SCHEDULE' | translate }}
									<!-- Todo visa ett miniatyrschema -->
								</div>
								<div *ngIf="geofence.IsTimeControlled==false">{{ 'Page.ToolTips.ALWAYS_ACTIVE' | translate }}</div>
								<div>
									<span *ngIf="calculateTrakkersTriggeringGeofence(geofence) == 0">{{ 'Page.Messages.X_UNITS_HAS_TRIGGERED_THIS_GEOFENCE' | translate | stringFormat: { 'X':0} }}</span>
									<span *ngIf="calculateTrakkersTriggeringGeofence(geofence) == 1">{{ 'Page.Messages.ONE_UNIT_HAS_TRIGGERED_THIS_GEOFENCE' | translate }}</span>
									<span *ngIf="calculateTrakkersTriggeringGeofence(geofence) > 1">{{ 'Page.Messages.X_UNITS_HAS_TRIGGERED_THIS_GEOFENCE' | translate | stringFormat: { 'X': calculateTrakkersTriggeringGeofence(geofence) } }}</span>
								</div>
							</div>
							<div class="boxlabel">{{ 'Page.Labels.UNITS' | translate }}</div>
							<div *ngIf="geofence.ActiveForAllUnits == false" class="event twoCols">
								<div>
									<div *ngIf="geofence.Trakkers.length == 0" style="padding-left: 5px;">{{ 'Page.Labels.NO_UNITS_CONNECTED' | translate }}</div>
									<div *ngFor="let trakker of geofence.Trakkers | sort:'Name'; trackBy:TrakkerIdentify" class="connectedObject">
										{{trakker.Name}}
										<button (click)="RemoveGeofenceFromObject(trakker,geofence)" title="{{ 'Page.Buttons.REMOVE' | translate }}">
											<img src="assets/icons/svg/remove.svg">
										</button>
									</div>
								</div>
								<div style="font-size: 40px !important;text-align: center;">
									<a (click)="StartAddTrakker(geofence)" title="{{ 'Page.ToolTips.CHANGE_CONNECTED_UNITS' | translate }}">
										<img src="assets/icons/svg/edit.svg" style="width: 42px; height: 42px;">
									</a>
								</div>
							</div>
							<div *ngIf="geofence.ActiveForAllUnits == true" class="event">
								{{ 'Page.Messages.GEOFENCE_ACTIVE_FOR_ALL_UNITS' | translate }}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div *ngIf="infoFocus=='createGeofence' && selectedGeofence">
				<app-creategeofence [trakkers]="unitList" [geofence]="selectedGeofence" (updateChanges)="updateGeofence($event)" (editScheduleEvent)="StartEditSchedule($event)" (emitEvent)="GeoComponentEvent($event)" (addTrackersEvent)="StartAddTrakker($event)" (saveGeofence)="SaveCreateGeofence($event)"></app-creategeofence>
			</div>

			<div *ngIf="infoFocus=='editGeofence'">
				<div class="sectionhead">
					<h2>{{ 'Page.Labels.EDIT_GEOFENCE' | translate }}</h2>
				</div>
				<h6>{{ 'Page.Labels.NAME' | translate }}</h6>
				<input type="text" class="form-control input-sm" placeholder="Geofence name" id="geofenceEditNameInput" value="{{selectedGeofence.Name}}">
				<h6>{{ 'Page.Labels.SHAPE' | translate }}</h6>
				<input type="radio" id="shapeChoice1" name="shape" value="round" style="display: none;">
				<label for="shapeChoice1" (click)="StartAddCircleGeofenceShape()" title="{{ 'Page.ToolTips.ROUND' | translate }}">
					<img src="assets/icons/svg/geofence-circle.svg" class="svg">
					<!-- Round -->
				</label>

				<input type="radio" id="shapeChoice2" name="shape" value="polygon" style="display: none;">
				<label for="shapeChoice2" (click)="StartAddPolygonGeofenceShape()" title="{{ 'Page.ToolTips.POLYGON' | translate }}">
					<img src="assets/icons/svg/geofence-polygon.svg" class="svg">
					<!-- Polygon -->
				</label>
				<!-- <p *ngIf="editShapeGeofence!=null" ><a (click)="StartEditGeofenceShape()"><i class="fas fa-edit"></i>{{ 'Page.Labels.EDIT_SHAPE' | translate }}</a></p> -->
				<!-- <p>How to....</p> -->
				<p><span (click)="StartEditGeofenceShape(selectedGeofence);">
						<img src="assets/icons/svg/edit.svg" class="svg">
						{{ 'Page.Labels.EDIT_SHAPE' | translate }}
					</span></p>
				<h6>{{ 'Page.Labels.TYPE' | translate }}</h6>
				<div class="switchGroup">
					<div class="switchOption" (click)="selectedGeofence.GeofenceType = 'Exit'" [class.inactive]="selectedGeofence.GeofenceType != 'Exit'" title="{{ 'Page.ToolTips.EXIT_GEOFENCE' | translate }}">
						<img src="assets/icons/svg/trakker-events/exit-geofence.svg" class="svg">
					</div>
					<div class="switchOption" (click)="selectedGeofence.GeofenceType = 'Enter'" [class.inactive]="selectedGeofence.GeofenceType != 'Enter'" title="{{ 'Page.ToolTips.ENTER_GEOFENCE' | translate }}">
						<img src="assets/icons/svg/trakker-events/enter-geofence.svg" class="svg">
					</div>
					<div class="switchOption" (click)="selectedGeofence.GeofenceType = 'Intersect'" [class.inactive]="selectedGeofence.GeofenceType != 'Intersect'" title="{{ 'Page.ToolTips.INTERSECT_GEOFENCE' | translate }}">
						<img src="assets/icons/svg/trakker-events/intersect-geofence.svg" class="svg">
					</div>
				</div>
				<!-- <p>Information about...</p> -->
				<h6>{{ 'Page.Labels.SCHEDULE' | translate }}</h6>
				<div class="switchGroup">
					<div class="switchOption" (click)="selectedGeofence.IsTimeControlled = false" [class.inactive]="selectedGeofence.IsTimeControlled != false" title="{{ 'Page.ToolTips.ALWAYS_ACTIVE' | translate }}">
						<img src="assets/icons/svg/infinity.svg" class="svg">
					</div>
					<div class="switchOption" (click)="selectedGeofence.IsTimeControlled = true" [class.inactive]="selectedGeofence.IsTimeControlled != true" title="{{ 'Page.ToolTips.CONTROLLED_BY_SCHEDULE' | translate }}">
						<img src="assets/icons/svg/calendar.svg" class="svg">
					</div>
				</div>
				<p>
					<span *ngIf="selectedGeofence.IsTimeControlled == true" (click)="StartEditSchedule()">
						<img src="assets/icons/svg/edit.svg" class="svg">
						{{ 'Page.Labels.EDIT_SCHEDULE' | translate }}
					</span>
					<span *ngIf="selectedGeofence.IsTimeControlled == false" class="inactive">
						<img src="assets/icons/svg/edit.svg" class="svg">
						{{ 'Page.Labels.EDIT_SCHEDULE' | translate }}
					</span>
					&nbsp;
				</p>
				<h3>{{ 'Page.Labels.UNITS' | translate }}</h3>
				<div class="" (click)="ToggleGeofenceActiveForAll()" *ngIf="selectedGeofence.ActiveForAllUnits == true" title="{{ 'Page.Messages.GEOFENCE_ACTIVE_FOR_ALL_UNITS' | translate }}">
					<img src="assets/icons/svg/toggle-on.svg" class="svg">
					<span>{{ 'Page.Messages.GEOFENCE_ACTIVE_FOR_ALL_UNITS' | translate }}</span>
				</div>
				<div class="" (click)="ToggleGeofenceActiveForAll()" *ngIf="selectedGeofence.ActiveForAllUnits == false" title="{{ 'Page.Messages.GEOFENCE_ONLY_ACTIVE_FOR_SELECTED_UNITS' | translate }}">
					<img src="assets/icons/svg/toggle-off.svg" class="svg">
					<span>{{ 'Page.Messages.GEOFENCE_ONLY_ACTIVE_FOR_SELECTED_UNITS' | translate }}</span>
				</div>

				<button class="btn" (click)="SaveEditGeofence()">{{ 'Page.Buttons.SAVE' | translate }}</button>
				<button class="btn" (click)="StopEditGeofence()">{{ 'Page.Buttons.CANCEL' | translate }}</button>
				<hr>
				<div class="boxlabel">{{ 'Page.Labels.TRAKKERS' | translate }}</div>
				<div class="event twoCols">
					<div>
						<div *ngIf="selectedGeofence.Trakkers.length == 0 && selectedGeofence.ActiveForAllUnits == false" style="padding-left: 5px;">{{ 'Page.Labels.NO_UNITS_CONNECTED' | translate }}</div>
						<div *ngIf="selectedGeofence.ActiveForAllUnits == true" style="padding-left: 5px;">{{ 'Page.Messages.GEOFENCE_ACTIVE_FOR_ALL_UNITS' | translate }}</div>
						<div *ngIf="selectedGeofence.ActiveForAllUnits != true">
							<div *ngFor="let trakker of selectedGeofence.Trakkers; trackBy:TrakkerIdentify" class="connectedObject">
								{{trakker.Name}}
								<button (click)="RemoveGeofenceFromObject(trakker,selectedGeofence);" title="{{ 'Page.Buttons.REMOVE' | translate }}">
									<img src="assets/icons/svg/remove.svg">
								</button>
							</div>
						</div>

					</div>
					<div style="font-size: 40px !important;text-align: center;">
						<a *ngIf="selectedGeofence.ActiveForAllUnits != true" (click)="StartAddTrakker(selectedGeofence);" title="{{ 'Page.ToolTips.CHANGE_CONNECTED_UNITS' | translate }}">
							<img src="assets/icons/svg/edit.svg" class="svg">
						</a>
					</div>
				</div>
			</div>

			<div *ngIf="infoFocus=='mapzones'">
				<!-- <div (click)="infoFocus = 'menu'">Close</div> -->
				<!-- <div class="sectionhead">
					<img src="assets/icons/svg/mapzone.svg" class="svg">
					<span class="header" [translate]>Page.Labels.MAPZONES</span>
				</div> -->
				<div class="sectionTools">
					<!-- <a class="searchButton" [class.inactive]="!searchMapzone" (click)="ToggleMapzoneSearch()" *ngIf="mapzoneList.length > 5" title="{{ 'Page.ToolTips.SEARCH_MAPZONE' | translate }}">
						<img src="assets/icons/svg/magnifying-glass.svg" class="svg">
					</a> -->

					<!-- <a class="insertButton" (click)="StartCreateMapzone();" title="{{ 'Page.Labels.CREATE_MAPZONE' | translate }}">
						<img src="assets/icons/svg/add.svg" class="svg">
					</a>

					<button class="btn-base btn-options">
						<img src="assets/icons/svg/add.svg" class="svgee">
						<span translate>Page.Labels.ADD_NEW_MAPZONE</span>
					</button> -->

					<div style="display: flex; justify-content: end; margin: 0 0 6px 0">
						<button type="button" class="btn-base btn-options" (click)="StartCreateMapzone();">
							<img class="svgee" src="assets/icons/svg/add.svg">
							<span [translate]>Page.Labels.ADD_NEW_MAPZONE</span>
						</button>
					</div>

					<!-- <div *ngIf="searchMapzone && mapzoneList.length > 5"> -->
					<div>
						<input class="form-control input-sm" id="searchMapzoneText" placeholder="{{ 'Page.Placeholders.SEARCH_MAPZONE_NAME' | translate }}" type="search" (input)="UpdateVisibleMapzones($event)" (keyup.escape)="ToggleMapzoneSearch()" (keyup.enter)="TryUseMapzone()" (keyup.shift.enter)="TryToggleSelectMapzone()">
						<!-- <button (click)="searchForMapzone()">search</button> -->
					</div>
				</div>
				<div>
					<!-- <span>{{ 'Page.ToolTips.CLICK_TO_CENTER_MAP_ON_MAPZONE' | translate }}</span> -->
					<div *ngFor="let mapzone of mapzoneSearchResult | sort:'Name'; trackBy:MapzoneIdentify" class="objectWrapper" [class.selected]="selectedMapzone == mapzone">
						<div class="object" style="display: flex; flex-direction: row; justify-content: space-between;" (click)="CenterOnMap(mapzone);selectedMapzone=(selectedMapzone==mapzone?null:mapzone);">
							<!-- <div class="icon" (click)="CenterOnMap(mapzone);$event.stopPropagation()">
								<img src="assets/icons/svg/mapzone.svg" class="svg">
							</div> -->
							<div style="display: flex; flex-direction: row; align-items: center;">
								<div class="icon">
									<img src="assets/icons/svg/mapzone.svg" class="svg">
								</div>
								<div>{{ mapzone.Name }}</div>
							</div>
							<a ngbTooltip="{{ 'Page.Labels.EDIT_NAME' | translate }}" class="editButton" (click)="StartEditMapzoneName(mapzone);">
								<img src="assets/icons/svg/edit.svg" class="svg hover-opacity">
							</a>
						</div>
						<div class="objectDetails" *ngIf="selectedMapzone == mapzone">
							<div class="sectionTools" style="display: flex; flex-direction: row; justify-content: space-between;">
								<!-- <a title="{{ 'Page.ToolTips.CLICK_TO_CENTER_MAP_ON_MAPZONE' | translate }}" (click)="CenterOnMap(mapzone);" >
									<img src="assets/icons/svg/mapzone.svg" class="svg">
								</a> -->
								<button class="btn-base btn-options" ngbTooltip="{{ 'Page.ToolTips.UPDATE_MAPZONE' | translate }}" (click)="VerifyUpdateMapzone(mapzone)">
									<img class="svgee" src="assets/icons/svg/needle.svg">
									<span translate>Page.Labels.UPDATE_MAPZONE</span>
								</button>
								<button class="btn-base btn-del" ngbTooltip="{{ 'Page.Buttons.DELETE' | translate }}" (click)="VerifyDeleteMapzone(mapzone)">
									<img class="svgee" src="assets/icons/svg/delete.svg">
									<span translate>Page.Buttons.DELETE</span>
								</button>

								<!-- <a title="{{ 'Page.Labels.UPDATE_MAPZONE' | translate }}" (click)="VerifyUpdateMapzone(mapzone)">
									<img src="assets/icons/svg/edit-mapzone.svg" class="svg">
								</a>
								<a title="{{ 'Page.Buttons.DELETE' | translate }}" class="deleteButton" (click)="VerifyDeleteMapzone(mapzone)">
									<img src="assets/icons/svg/delete.svg" class="svg">
								</a> -->
							</div>
						</div>
					</div>
				</div>
			</div>

			<div [class.visible]="infoFocus=='createMapzone' || infoFocus=='editMapzoneName'" class="hidden">
				<div class="sectionhead">
					<h2 *ngIf="infoFocus=='createMapzone'">{{ 'Page.Labels.CREATE_MAPZONE' | translate }}</h2>
					<h2 *ngIf="infoFocus=='editMapzoneName'">{{ 'Page.Labels.EDIT_NAME' | translate }}</h2>
				</div>
				<input type="text" class="form-control input-sm" id="mapzoneNameInput" placeholder="{{ 'Page.Labels.NAME' | translate }}" (keyup.escape)="infoFocus='mapzones'" (keyup.enter)="OnMapzoneNameInputEnter()">
				<div class="f-row" style="justify-content: flex-start; margin: 16px 0;">
					<button class="btn-base btn-options" (click)="infoFocus='mapzones'" [translate]>{{ 'Page.Buttons.CANCEL' | translate }}</button>
					<button *ngIf="infoFocus=='createMapzone'" class="btn-base btn-save" (click)="CreateMapzone()" [translate]>{{ 'Page.Buttons.SAVE' | translate }}</button>
					<button *ngIf="infoFocus=='editMapzoneName'" class="btn-base btn-options" (click)="UpdateMapzone()" [translate]>{{ 'Page.Buttons.SAVE' | translate }}</button>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="modalWrapper" [class.visible]="modal!=null" (click)="CancelModal()" *ngIf="IsDashboard==false">
	{{loadingProgress()}}

	<!-- <div class="modal" id="loadingModal" [class.visible]="modal=='loading'" (click)="$event.stopPropagation()">
		<h2>{{'Page.Labels.LOADING' | translate}}</h2>
		<div class="meter">
			<span [style.width.%]="loadingProgress()"></span>
		</div>
	</div> -->
	<div class="modal" id="infoModal" [class.visible]="modal=='info'" (click)="$event.stopPropagation()">
		<h2>{{'Page.Labels.INFORMATION' | translate}}</h2>
		<p></p>
		<button class="btn defaultAction" (click)="CancelModal();">{{ 'Page.Buttons.OK' | translate }}</button>
	</div>
	<div class="modal" id="errorModal" [class.visible]="modal=='error'" (click)="$event.stopPropagation()">
		<h2>{{'Page.Labels.ERROR' | translate}}</h2>
		<p>{{'Page.ErrorMessages.ERROR_MESSAGE' | translate}}</p>
		<button class="btn defaultAction" (click)="CancelModal();">{{ 'Page.Buttons.OK' | translate }}</button>
	</div>
	<div class="modal" [class.visibleGrid]="modal=='addContact'" (click)="$event.stopPropagation()">
		<ng-container *ngIf="createContact==false">
			<div>
				<h3 class="popup-header">{{ 'Page.ToolTips.CHANGE_CONNECTED_CONTACTS' | translate }}</h3>
				<button type="button" class="btn-base btn-options" style="margin: 8px 0;" title="{{ 'Page.Labels.CREATE_CONTACT' | translate }}" (click)="StartCreateContact();">
					<img class="svgee" src="assets/icons/svg/add.svg">
					<span [translate]>Page.Labels.CREATE_CONTACT</span>
				</button>

				<input class="form-control input-sm defaultAction" id="searchContactText" placeholder="{{ 'Page.Placeholders.SEARCH_CONTACT_NAME' | translate }}" type="text" (keyup)="UpdateVisibleContacts($event)" (keyup.enter)="TryToggleContact();$event.stopPropagation();">
			</div>
			<div class="list">
				<ng-container *ngIf="visibleContacts.length == 0">
					{{ 'Page.ToolTips.NO_CONTACTS_FOUND' | translate }}
				</ng-container>
				<ng-container *ngFor="let contact of visibleContacts | sort:'Name'; trackBy:ContactIdentify">
					<div *ngIf="IsConnectedToObject(contact)==false" class="connectionObject" (click)="AddContact(selectedObject,contact)">
						<div>
							<img src="assets/icons/svg/checkbox-false.svg" class="smal-svg" style="margin-top: -3px;">
							<span>{{ contact.Name }}</span>
						</div>
						<div></div>
						<!-- <span>({{contact.Email}}, {{contact.Number}})</span> -->
					</div>
					<div *ngIf="IsConnectedToObject(contact)==true" class="connectionObject connected" title="Already added (click to remove)" (click)="RemoveContact(selectedObject,contact)">
						<div>
							<img src="assets/icons/svg/checkbox-true.svg" class="smal-svg" style="margin-top: -3px;">
							<span>{{ contact.Name }}</span>
						</div>
						<div></div>
						<!-- <span>({{contact.Email}}, {{contact.Number}})</span> -->
					</div>
				</ng-container>
			</div>
			<div class="f-row"><button class="btn-base btn-options" (click)="modal=null;">{{ 'Page.Buttons.DONE' | translate }}</button></div>
		</ng-container>
		<ng-container *ngIf="createContact==true">
			<h3>{{ 'Page.Labels.CREATE_CONTACT' | translate }}</h3>
			<div class="form">
				<label>{{ 'Page.Labels.NAME' | translate }}</label>
				<input type="text" class="form-control input-sm defaultAction" id="contactNameInput" placeholder="{{ 'Page.Labels.NAME' | translate }}">
				<label>{{ 'Page.Labels.EMAIL' | translate }}</label>
				<input type="text" class="form-control input-sm" id="contactEmailInput" placeholder="{{ 'Page.Labels.EMAIL' | translate }}">
				<label>{{ 'Page.Labels.PHONE_NUMBER' | translate }}</label>
				<input type="text" class="form-control input-sm" id="contactNumberInput" placeholder="{{ 'Page.Labels.PHONE_NUMBER' | translate }}" (keydown)="PreventShortcuts($event)">
			</div>
			<div class="f-row">
				<button class="btn-base btn-options" (click)="createContact=false">{{ 'Page.Buttons.CANCEL' | translate }}</button>
				<button class="btn-base btn-save" (click)="CreateContact()">{{ 'Page.Buttons.SAVE' | translate }}</button>
			</div>
		</ng-container>
	</div>
	<div class="modal" [class.visibleGrid]="modal=='addGeofence'" (click)="$event.stopPropagation()">
		<div>
			<h3 class="popup-header">{{ 'Page.ToolTips.CHANGE_CONNECTED_GEOFENCES' | translate }} </h3>
			<div (click)="modal=null;StartCreateGeofence()" class="btn-base btn-options" style="margin: 8px 0 8px 0;" title="{{ 'Page.Labels.CREATE_GEOFENCE' | translate }}">
				<img class="svgee" src="assets/icons/svg/add.svg">
				<span>{{ 'Page.Labels.CREATE_GEOFENCE' | translate }}</span>
			</div>
			<input class="form-control input-sm defaultAction" id="searchGeofenceTextAdd" placeholder="{{ 'Page.ToolTips.SEARCH_GEOFENCE' | translate }}" type="text" (keydown)="PreventShortcuts($event)" (keyup)="UpdateVisibleAddGeofences($event)" (keyup.enter)="TryToggleGeofence();$event.stopPropagation();">

		</div>
		<div class="list">
			<ng-container *ngFor="let geofence of visibleAddGeofences | sort:'Name'; trackBy:GeofenceIdentify">
				<div *ngIf="IsGeofenceConnectedToObject(geofence)==false" class="connectionObject" (click)="AddGeofenceToObject(selectedObject,geofence)">
					<div>
						<img src="assets/icons/svg/checkbox-false.svg" class="smal-svg" style="margin-top: -3px;">
						<span>{{ geofence.Name }}</span>
					</div>
					<div></div>
				</div>
				<div *ngIf="IsGeofenceConnectedToObject(geofence)==true" class="connectionObject connected" (click)="RemoveGeofenceFromObject(selectedObject,geofence)">
					<div>
						<img src="assets/icons/svg/checkbox-true.svg" class="smal-svg" style="margin-top: -3px;">
						<span>{{ geofence.Name }}</span>
					</div>
					<div></div>
				</div>
			</ng-container>
		</div>
		<div class="f-row"><button class="btn-base btn-options" (click)="modal=null;">{{ 'Page.Buttons.DONE' | translate }}</button></div>
	</div>
	<div class="modal" [class.visible]="modal=='verifyDeleteGeofence'" *ngIf="modal=='verifyDeleteGeofence' && selectedGeofence != null" (click)="$event.stopPropagation()">
		<h2>{{ 'Page.ToolTips.DELETE_GEOFENCE' | translate }}</h2>
		<p>{{ 'Page.Messages.CONFIRM_DELETE_GEOFENCE' | translate : { name: selectedGeofence.Name} }}</p>
		<div class="f-row">
			<button class="btn-base btn-options" (click)="modal=null;">{{ 'Page.Buttons.CANCEL' | translate }}</button>
			<button class="btn-base btn-del defaultAction" (click)="DeleteGeofence();">{{ 'Page.Buttons.DELETE' | translate }}</button>
		</div>
	</div>

	<div class="modal" [class.visibleGrid]="modal=='addTrakker'" (click)="$event.stopPropagation()">
		<div>
			<h2>{{ 'Page.ToolTips.CHANGE_CONNECTED_UNITS' | translate }} </h2>
			<input class="form-control input-sm defaultAction" id="searchText" placeholder="{{ 'Page.Labels.SEARCH_UNIT' | translate }}" type="text" (keydown)="PreventShortcuts($event)" (keyup)="UpdateVisibleAddTrakkers($event)" (keyup.enter)="TryToggleTrakker();$event.stopPropagation();">
		</div>
		<div class="list">
			<ng-container *ngFor="let trakker of visibleAddTrakkers | sort:'Name'; trackBy:TrakkerIdentify">
				<div *ngIf="IsTrakkerConnectedToObject(trakker)==false" class="connectionObject" (click)="AddTrakkerToObject(selectedObject,trakker)">
					<img src="assets/icons/svg/checkbox-false.svg" class="smal-svg" style="margin-top: -3px;">
					{{trakker.Name}}
				</div>
				<div *ngIf="IsTrakkerConnectedToObject(trakker)==true" class="connectionObject connected" (click)="RemoveGeofenceFromObject(trakker,selectedObject)">
					<img src="assets/icons/svg/checkbox-true.svg" class="smal-svg" style="margin-top: -3px;">
					{{trakker.Name}}
				</div>
			</ng-container>
		</div>
		<div class="f-row"><button class="btn-base btn-options" (click)="modal=null;">{{ 'Page.Buttons.DONE' | translate }}</button></div>
	</div>

	<div class="modal" [class.visible]="modal=='verifyDeleteMapzone'" *ngIf="modal=='verifyDeleteMapzone' && selectedMapzone != null" (click)="$event.stopPropagation()">
		<h2>{{ 'Page.ToolTips.DELETE_MAPZONE' | translate }}</h2>
		<p>{{ 'Page.Messages.CONFIRM_DELETE_MAPZONE' | translate : { name: selectedMapzone.Name} }}</p>
		<button class="btn defaultAction" (click)="DeleteMapzone();">{{ 'Page.Buttons.DELETE' | translate }}</button>
		<button class="btn" (click)="modal=null;">{{ 'Page.Buttons.CANCEL' | translate }}</button>
	</div>

	<div class="modal" [class.visible]="modal=='verifyUpdateMapzone'" *ngIf="modal=='verifyUpdateMapzone' && selectedMapzone != null" (click)="$event.stopPropagation()">
		<h2>{{ 'Page.Labels.UPDATE_MAPZONE' | translate }}</h2>
		<p>{{ 'Page.Messages.CONFIRM_UPDATE_MAPZONE' | translate : { name: selectedMapzone.Name} }}</p>
		<button class="btn defaultAction" (click)="UpdateMapzone();">{{ 'Page.Buttons.SAVE' | translate }}</button>
		<button class="btn" (click)="modal=null;">{{ 'Page.Buttons.CANCEL' | translate }}</button>
	</div>

	<div class="fullSizeModal modal" [class.visible]="modal=='changeIcon'" (click)="$event.stopPropagation()">
		<div>
			<h2>{{ 'Page.Labels.CHANGE_ICON' | translate }}</h2>
			<div class="closeModal" (click)="modal=null;">
				<img src="assets/icons/svg/close.svg" class="svg">
			</div>
		</div>
		<iconpicker *ngIf="loadedIconsOnce" [object]="selectedObject" (result)="ngOnChanges($event)"></iconpicker>
		<div class="f-row" style="margin: 8px 0;"><button class="btn" (click)="modal=null;">{{ 'Page.Buttons.DONE' | translate }}</button></div>
	</div>
	<div *ngIf="selectedGeofenceScheduleId && selectedGeofenceScheduleId >= 0 && modal=='scheduleEditor'" class="fullSizeModal modal" [class.visible]="modal=='scheduleEditor'" (click)="$event.stopPropagation()">
		<div>
			<h2>{{ 'Page.Labels.SCHEDULE' | translate }}</h2>
			<div class="closeModal" (click)="modal=null;">
				<img src="assets/icons/svg/close.svg" class="svg">
			</div>
		</div>
		<scheduleEditor *ngIf="modal=='scheduleEditor' && selectedGeofenceScheduleId >= 0" [id]="selectedGeofenceScheduleId"></scheduleEditor>
		<div class="f-row"><button class="btn-base btn-options" (click)="CancelScheduleEditor()" [disabled]="scheduleError">{{ 'Page.Buttons.DONE' | translate }}</button></div>
	</div>
</div>